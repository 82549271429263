<template>
    <div>
        <cosecha-urgente></cosecha-urgente>
        <screenshot name-pdf="Cruva de crecimiento"></screenshot>
        <b-row class="match-height">
            <b-col md="12">
                <good-table-basic
                    title=""
                    :data-table="dataTable"
                ></good-table-basic>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import CosechaUrgente from "@/views/clients/camanchaca/components/CosechaUrgente";
import Screenshot from "@/views/clients/camanchaca/components/Screenshot";
import GoodTableBasic from "@/views/clients/camanchaca/components/GoodTableBasic.vue";
import {BCol, BRow} from 'bootstrap-vue';

export default {
    components: {
        CosechaUrgente,
        Screenshot,
        GoodTableBasic,
        BCol,
        BRow
    },
    data() {
        return {
            dataTable: {
                planning: [
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Puerto Varas",
                        nombre_centro: "CHAIQUÉN",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Cochamó",
                        nombre_centro: "PUCHEGUIN",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Cochamó",
                        nombre_centro: "COSTA PUCHEGUIN",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Cochamó",
                        nombre_centro: "MARIMELI",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Cochamó",
                        nombre_centro: "FARELLONES",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Cochamó",
                        nombre_centro: "RIO CHILCO 1",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Cochamó",
                        nombre_centro: "CHILCO 2",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Cochamó",
                        nombre_centro: "CASCAJAL",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Cochamó",
                        nombre_centro: "FACTORIA",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Hualaihue",
                        nombre_centro: "PUELCHE",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Hualaihue",
                        nombre_centro: "MANIHUEICO",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Hualaihue",
                        nombre_centro: "CONTAO",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Hualaihue",
                        nombre_centro: "RIO CHAGUAL",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Hualaihue",
                        nombre_centro: "AULEN",
                        descanso: "ene-mar 2019"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Calbuco",
                        nombre_centro: "SAN JOSÉ",
                        descanso: "feb-abr 2018"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Calbuco",
                        nombre_centro: "PENASMO",
                        descanso: "feb-abr 2018"
                    },
                    {
                        region: "X",
                        provincia: "Chonchi",
                        comuna: "Chiloé",
                        nombre_centro: "PILPILEHUE",
                        descanso: "abr-jun 2018"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Quellen",
                        nombre_centro: "AHONI",
                        descanso: "abr-jun 2018"
                    },
                    {
                        region: "X",
                        provincia: "Llanquihue",
                        comuna: "Chonchi",
                        nombre_centro: "TERAO",
                        descanso: "abr-jun 2018"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Chaitén",
                        nombre_centro: "PUMALIN",
                        descanso: "jun-ago 2019"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Chaitén",
                        nombre_centro: "PUNTA ISLOTES",
                        descanso: "jun-ago 2019"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Chaitén",
                        nombre_centro: "EDWARDS",
                        descanso: "jul-sep 2019"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Chaitén",
                        nombre_centro: "YELCHO",
                        descanso: "dic 2019-feb 2020"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Chaitén",
                        nombre_centro: "CHILCO",
                        descanso: "dic 2019-feb 2020"
                    },
                    {
                        region: "X",
                        provincia: "Palena",
                        comuna: "Chaitén",
                        nombre_centro: "FIORDO LARGO",
                        descanso: "dic 2019-feb 2020"
                    }
                ],
                columns: [
                    {
                        label: "Región",
                        field: "region"
                    },
                    {
                        label: "Provincia",
                        field: "provincia"
                    },
                    {
                        label: "Comuna",
                        field: "comuna"
                    },
                    {
                        label: "Nombre centro",
                        field: "nombre_centro"
                    },
                    {
                        label: "Descanso",
                        field: "descanso"
                    }
                ],
                verDetalle: false // para mostrar o no el ver detalle en el componente GoodTable
            }
        };
    }
};
</script>

<style lang="scss">
span{
    font-size: 14px;
}
</style>
